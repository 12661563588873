import version from 'environments-version.json';
import { EnvironmentConfig } from '@lib/rs-ngx';

export const environment: EnvironmentConfig = {
  production: false,
  apiUrl: 'https://api.ord.tst.rentasolutions.org/ord/webapi',
  useMockedServices: false,
  enableSentryMonitoring: true,
  sentryReleaseName: 'test-divc-ui-12067',
  environment: 'test',
  authUrl: 'https://authentication.tst.rentasolutions.org',
  topBarEnvironment: 'tst',
  topBarCdn: 'https://cdn.rentasolutions.org/topbar/tst/v1.3/',
  weblateEnvironment: 'tst',
  version: version?.test
};
